<template>
  <router-view/>
</template>

<script>
export default {
  methods: {
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      return flag;
    }
  },
  mounted() {
    console.log(window.location.href)
    let mobile = this.isMobile()
    if(mobile == null) {
      console.log('pc')
      this.$router.push({name:'index'});
    }else {
      console.log('mobile')
      if(window.location.href=='https://www.gafenghui.com/index.html#/mProduct'){
        this.$router.push({name:'mProduct'});
      }else{
        this.$router.push({name:'mIndex'});
      }
    }

  }
}
</script>

<style>
  *{
    padding: 0;
    margin: 0;
  }
</style>
