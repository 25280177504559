import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import( '../views/index/index'),
    redirect:'/home',
    children:[
      {
        path: '/home',
        name: 'home',
        component: () => import( '../views/home/home'),
      },
      {
        path: '/project',
        name: 'project',
        component: () => import( '../views/project/project'),
      },
      {
        path: '/development',
        name: 'development',
        component: () => import( '../views/development/development'),
      },
      {
        path: '/things',
        name: 'things',
        component: () => import( '../views/things/things'),
      },
      {
        path: '/analysis',
        name: 'analysis',
        component: () => import( '../views/analysis/analysis'),
      },
      {
        path: '/security',
        name: 'security',
        component: () => import( '../views/security/security'),
      },
      {
        path: '/partner',
        name: 'partner',
        component: () => import( '../views/partner/partner'),
      },
      {
        path: '/introduction',
        name: 'introduction',
        component: () => import( '../views/introduction/introduction'),
      },
      {
        path: '/honor',
        name: 'honor',
        component: () => import( '../views/honor/honor'),
      },
      {
        path: '/event',
        name: 'event',
        component: () => import( '../views/event/event'),
      },
      {
        path: '/news',
        name: 'news',
        component: () => import( '../views/news/news'),
      },
      {
        path: '/contact_us',
        name: 'contact_us',
        component: () => import( '../views/contact_us/contact_us'),
      },
      {
        path: '/news_info',
        name: 'news_info',
        component: () => import( '../views/news_info/news_info'),
      },
    ]
  },
  {
    path: '/mIndex',
    name: 'mIndex',
    component: () => import( '../views/mIndex/mIndex'),
    redirect:'/mhome',
    children: [
      {
        path: '/mhome',
        name: 'mhome',
        component: () => import( '../views/mhome/mhome'),
      },
      {
        path: '/mAbout',
        name: 'mAbout',
        component: () => import( '../views/mAbout/mAbout'),
      },
      {
        path: '/mContent',
        name: 'mContent',
        component: () => import( '../views/mContent/mContent'),
      },
      {
        path: '/mNews',
        name: 'mNews',
        component: () => import( '../views/mNews/mNews'),
      },
      {
        path: '/mDetail',
        name: 'mDetail',
        component: () => import( '../views/mDetail/mDetail'),
      },
    ]
  },
  {
    path: '/mProduct',
    name: 'mProduct',
    component: () => import( '../views/mProduct/mProduct'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
